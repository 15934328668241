import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import Moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-grid-system";
import { BsFillShareFill } from "react-icons/bs";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import { useParams } from 'react-router-dom';


import {
  NativeBaseProvider,
  Box,
  Text,
  Modal,
  VStack,
  HStack,
  Button,
  ScrollView,
  Pressable,
  Badge,
  Divider,
  Spacer,
  Center,
} from "native-base";

import "./App.css";

const App = () => {

  let [empcode,setEmpcode] = useState("")
  
  const componentRef = useRef();
   useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let emp = urlParams.get('emp');
    console.log(emp);
    // setEmpcode(emp);
    empcode = emp
    setEmpcode(emp);
  }, []);

  //////////////
  // const handleDownloadPDF = () => {
  //   const options = {
  //     filename: "screen.pdf",
  //     jsPDF: { format: "a4" },
  //     html2canvas: { scale: 2 },
  //   };

  //   html2pdf()
  //     .set(options)
  //     .from(componentRef.current)
  //     .output("blob")
  //     .then((pdfBlob) => {
  //       const pdfUrl = URL.createObjectURL(pdfBlob);
  //       const link = document.createElement("a");
  //       link.href = pdfUrl;
  //       link.setAttribute("download", options.filename);
  //       link.click();
  //       handleSharePDF();
  //     })
  //     .catch((error) => {
  //       console.error("Error generating PDF:", error);
  //     });
  // };

  const handleSharePDF = () => {
    const options = {
      filename: "screen.pdf",
      jsPDF: { format: "a4" },
      html2canvas: { scale: 2 },
    };

    html2pdf()
      .set(options)
      .from(componentRef.current)
      .output("blob")
      .then((pdfBlob) => {
        if (navigator.share) {
          const file = new File([pdfBlob], options.filename, {
            type: "application/pdf",
          });
          const filesArray = [file];
          navigator
            .share({
              files: filesArray,
            })
            .then(() => {
              console.log("PDF shared successfully");
            })
            .catch((error) => {
              console.error("Error sharing PDF:", error);
            });
            updateflag();
        } else {
          console.log("Web Share API not supported");
        }
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  let [data, setData] = useState([]);

  useEffect(() => {
    summery();
    todayd();
    activitydate();
    orderDate();
    returnDate();
  }, []);

  ///////////
  const updateflag = async()=>{
    // let vendetail = await AsyncStorage.getItem('userInfo');
    //   let empid = JSON.parse(vendetail);
  
    var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({
    "punchdate": Moment(todayDate).format('YYYY-MM-DD'),
    "enterBy": empcode.replace(/"/g, ""),
  });
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  fetch("https://crm.romsons.com:8080/EodShareUpdate", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));
  }










  ////////////

  const summery = async () => {
    // console.log(empcode,"empcodeempcodeempcode");
    try {
      const response = await fetch(
        "https://crm.romsons.com:8080/EODAttendance",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            enterBy: empcode.replace(/"/g, ""),
          }),
          
        }
      );

      const result = await response.json();
      const arr = result.data;
      setData(arr);
      console.log(data,"datatatatat")
    } catch (error) {
      console.log("error", error);
    }
  };

  //modal

  const [placement, setPlacement] = useState(undefined);
  const [open, setOpen] = useState(false);

  const openModal = (placement) => {
    punchinDate();
    setOpen(true);
    setPlacement(placement);
  };

  ///modal Data
  let [punchinDates, setPunchinDates] = React.useState([]);

  const punchinDate = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      enterBy: empcode.replace(/"/g, ""),
    });
    console.log(raw,"reawawawaw");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://crm.romsons.com:8080/EodNotPunchIn", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let arr = JSON.parse(result);
        punchinDates = arr.data;
        setPunchinDates(punchinDates);
      })
      .catch((error) => console.log("error", error));
  };

  /////////////
  let [todayDate, setTodayDate] = useState([]);
  const todayd = () => {
    todayDate = Moment() ;
    setTodayDate(todayDate);
    console.log(todayDate,"todayagy")
  };

  ////

  const orderDate = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      enterBy: empcode.replace(/"/g, ""),
    });
console.log(raw,"reee");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://crm.romsons.com:8080/EodDate", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let arr = JSON.parse(result);
        dates = arr.data;
        setDates(dates);
        orderData();
      })
      .catch((error) => console.log("error", error));
  };

  const orderData = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      enterBy: empcode.replace(/"/g, ""),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://crm.romsons.com:8080/EodOrder", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let arr = JSON.parse(result);
        // console.log(arr, "testttt");
        orderdatas = arr.data;
        setOrderdatas(orderdatas);

        console.log(orderdatas, "gdygywd");
      })
      .catch((error) => console.log("error", error));
  };

  ////order data///

  let [dates, setDates] = React.useState([]);
  let [orderdatas, setOrderdatas] = React.useState([]);

  const orderDatebutton = async (dd) => {
    // let vendetail = await AsyncStorage.getItem('userInfo');
    // let empid = JSON.parse(vendetail);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      enterDate: Moment(dd).format("YYYY-MM-DD"),
      enterBy: empcode.replace(/"/g, ""),
    });
    // console.log(raw, "iiiii");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://crm.romsons.com:8080/EodDatebutton", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let arr = JSON.parse(result);
        // console.log(arr, "testttt");
        dates = arr.data;
        setDates(dates);
        orderDatabutton(dd);
        // console.log(dates, "orderDate");
      })
      .catch((error) => console.log("error", error));
  };

  const orderDatabutton = async (dd) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      enterDate: Moment(dd).format("YYYY-MM-DD"),
      enterBy: empcode.replace(/"/g, ""),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://crm.romsons.com:8080/EodOrderbutton", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let arr = JSON.parse(result);
        // console.log(arr, "testttt");
        orderdatas = arr.data;
        setOrderdatas(orderdatas);

        // console.log(orderdatas, "gdygywd");
      })
      .catch((error) => console.log("error", error));
  };
  ////////////////retuen
  let [returndates, setReturndates] = React.useState([]);
  let [returndatas, setReturndatas] = React.useState([]);

  const returnDate = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      enterBy: empcode.replace(/"/g, ""),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://crm.romsons.com:8080/EodDateReturn", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let arr = JSON.parse(result);
        // console.log(arr, "testttt");
        returndates = arr.data;
        setReturndates(returndates);
        returnData();
        // console.log(returndates, "gdygywd");
      })
      .catch((error) => console.log("error", error));
  };

  const returnData = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      enterBy: empcode.replace(/"/g, ""),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://crm.romsons.com:8080/EodReturn", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let arr = JSON.parse(result);
        // console.log(arr, "testttt");
        returndatas = arr.data;
        setReturndatas(returndatas);

        // console.log(returndatas, "returndata");
      })
      .catch((error) => console.log("error", error));
  };

  /////////////ACtivity
  let [activitydatas, setActivitydatas] = React.useState([]);
  let [activitydates, setActivitydates] = React.useState([]);
  const activityData = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      enterBy: empcode.replace(/"/g, ""),
    });
    // console.log(raw, "rawrawarawarawaraw");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://crm.romsons.com:8080/ActivityData", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let arr = JSON.parse(result);
        // console.log(arr, "testttt");
        activitydatas = arr.data;
        setActivitydatas(activitydatas);

        // console.log(activitydatas, "gdygywd");
      })
      .catch((error) => console.log("error", error));
  };

  const activitydate = async () => {
    // let vendetail = await AsyncStorage.getItem('userInfo');
    // let empid = JSON.parse(vendetail);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      enterBy: empcode.replace(/"/g, ""),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://crm.romsons.com:8080/EODActivityDate", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let arr = JSON.parse(result);
        // console.log(arr, "testttt");
        activitydates = arr.data;
        setActivitydates(activitydates);
        activityData();
        // console.log(returndates, "gdygywd");
      })
      .catch((error) => console.log("error", error));
  };

  ////////////

  const returnDatebutton = async (dd) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      enterDate: Moment(dd).format("YYYY-MM-DD"),
      enterBy: empcode.replace(/"/g, ""),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://crm.romsons.com:8080/EodDateReturnbutton", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let arr = JSON.parse(result);
        // console.log(arr, "testttt");
        returndates = arr.data;
        setReturndates(returndates);
        returnDatabutton(dd);
        // console.log(returndates, "gdygywd");
      })
      .catch((error) => console.log("error", error));
  };

  const returnDatabutton = async (dd) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      enterDate: Moment(dd).format("YYYY-MM-DD"),
      enterBy: empcode.replace(/"/g, ""),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://crm.romsons.com:8080/EodReturnbutton", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let arr = JSON.parse(result);
        // console.log(arr, "testttt");
        returndatas = arr.data;
        setReturndatas(returndatas);

        // console.log(returndatas, "return");
      })
      .catch((error) => console.log("error", error));
  };

  const activitydatebutton = async (dd) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      enterDate: Moment(dd).format("YYYY-MM-DD"),
      enterBy: empcode.replace(/"/g, ""),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://crm.romsons.com:8080/EODActivityDatebutton", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let arr = JSON.parse(result);
        // console.log(arr, "testttt");
        activitydates = arr.data;
        setActivitydates(activitydates);
        activityDatabutton(dd);
        // console.log(returndates, "gdygywd");
      })
      .catch((error) => console.log("error", error));
  };

  const activityDatabutton = async (dd) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      enterDate: Moment(dd).format("YYYY-MM-DD"),
      enterBy: empcode.replace(/"/g, ""),
    });
    // console.log(raw, " activityDatabutton");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://crm.romsons.com:8080/ActivityDatabutton", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let arr = JSON.parse(result);
        // console.log(arr, "testttt");
        activitydatas = arr.data;
        setActivitydatas(activitydatas);

        // console.log(activitydatas, "activitydatas");
      })
      .catch((error) => console.log("error", error));
  };

  ////genrateSummery

  const GenSummery = (dd) => {
    // console.log(dd, "anubhav");
    setTodayDate(dd);
    orderDatebutton(dd);
    returnDatebutton(dd);
    activitydatebutton(dd);
    summerybutton(dd);
  };

  const summerybutton = async (dd) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      enterDate: Moment(dd).format("YYYY-MM-DD"),
      enterBy: empcode.replace(/"/g, ""),
    });
    console.log(raw,"trtrtrtr");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://crm.romsons.com:8080/EODAttendancebutton", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let arr = JSON.parse(result);
        data = arr.data;
        setData(data);
        // console.log(data,"uwherguwetsruyqtriusyetrkwqutriew7");
      })
      .catch((error) => console.log("error", error));
  };

  //Modal Overlay for React Portal
  const ModalOverlay = (props) => {
    return (
      <Modal
        mt={"56"}
        maxHeight={"350px"}
        isOpen={open}
        onClose={() => setOpen(false)}
        safeAreaTop={true}
      >
        <Modal.Content maxWidth="350" {...styles[placement]}>
          <Modal.CloseButton />
          <Modal.Header>Select Pending Eod Date</Modal.Header>
          <Modal.Body>
            {punchinDates.map((res, ind) => {
              return (
                <ScrollView key={ind}>
                  <Pressable
                    px={"3"}
                    py={"3"}
                    onPress={() => {
                      GenSummery(res.punch_date);
                      setOpen(false);
                    }}
                  >
                    <Pressable
                      onPress={() => {
                        GenSummery(res.punch_date);
                        setOpen(false);
                      }}
                    >
                      {({ isHovered, isFocused, isPressed }) => {
                        return (
                          <Box
                            borderWidth="1"
                            height={"70px"}
                            borderColor="coolGray.300"
                            shadow="3"
                            bg={
                              isPressed
                                ? "coolGray.200"
                                : isHovered
                                ? "coolGray.200"
                                : "coolGray.100"
                            }
                            p="5"
                            rounded="8"
                            style={{
                              transform: [
                                {
                                  scale: isPressed ? 0.96 : 1,
                                },
                              ],
                            }}
                          >
                            <HStack>
                              <Box>
                                <Badge
                                  colorScheme="#F57F17"
                                  _text={{
                                    color: "white",
                                  }}
                                  variant="solid"
                                  rounded="4"
                                ></Badge>
                              </Box>
                            </HStack>
                            <Text textAlign={"center"}>
                              {Moment(res.punch_date).format("DD-MM-YYYY")}
                            </Text>
                          </Box>
                        );
                      }}
                    </Pressable>
                  </Pressable>
                </ScrollView>
              );
            })}
          </Modal.Body>
        </Modal.Content>
      </Modal>
    );
  };

  ////////
  return (
    <NativeBaseProvider>
      <HStack space={2} alignItems="center">
        <Button
          mx={"1"}
          mt={"1"}
          colorScheme={"green"}
          variant={"outline"}
          onPress={() => openModal("right")}
        >
          Pending EOD
        </Button>
        <Spacer />
        <Button right={"2"} mt={"1"} variant="outline" colorScheme="success" onPress={handleSharePDF}>
          
            <BsFillShareFill onClick={handleSharePDF}/>
        
        </Button>
      </HStack>

      <ScrollView>
        <div
          ref={componentRef}
          style={{ height: "window.innerHeight", width: "100%" }}
        >
          {/* Date Container */}
          <Center>
            <Text
              bold
              fontSize="3xl"
              style={{ marginTop: "1.5rem"}}
            >
              {Moment(todayDate).format("DD-MMM-YYYY")}
            </Text>
          </Center>

          {/* Punch In Punch Out Container */}
          <div className="mt-4">
            {data.map((res, ind) => (
              <Center key={ind} flex={["1", "1/2", "1/3", "1/4"]} mb={4}>
                <HStack space={"10"}>
                  <VStack>
                    <Text bold>Punch-In</Text>
                    <Text bold color={"green.900"}>
                      {Moment(res.punch_in).format("hh:mm:ss a")}
                    </Text>
                  </VStack>
                  <VStack>
                    <Text bold>Punch-Out</Text>
                    <Text bold color={"#F57F17.500"}>
                      {res.punch_out &&
                        Moment(res.punch_out).format("hh:mm:ss a")}
                    </Text>
                  </VStack>
                </HStack>
              </Center>
            ))}
          </div>

          {dates && orderdatas.length > 0 ? (
            <>
              {dates.map((res, ind) => {
                return (
                  <div key={ind}>
                    <Container>
                      <Row>
                        <Col>
                          <p
                            className="text-center rounded"
                            style={{
                              marginTop: "2rem",
                              backgroundColor: "#FF9966",
                              padding: "0.5rem",
                              color: "black",
                            }}
                          >
                            {res.outlet_id}, {res.outlet_name}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ fontWeight: "bold" }}>
                          <p
                            className="text-left text-bold"
                            style={{ color: "#CC7F3B", marginTop: "1rem" }}
                          >
                            Type: Order, OrderID: {res.order_id}
                          </p>
                        </Col>
                      </Row>
                    </Container>
                    <Container className="section-containers">
                      <Row className="table-header">
                        <Col xs={4} sm={3}>
                          <p
                            className="col-headings"
                            style={{ marginTop: "2rem" }}
                          >
                            SKU Name
                          </p>
                        </Col>
                        <Col>
                          <p
                            className="col-headings"
                            style={{ marginTop: "2rem", textAlign: "right" }}
                          >
                            Unit Price
                          </p>
                        </Col>
                        <Col>
                          <p
                            className="col-headings"
                            style={{ marginTop: "2rem", textAlign: "right" }}
                          >
                            Unit
                          </p>
                        </Col>
                        <Col>
                          <p
                            className="col-headings"
                            style={{ marginTop: "2rem", textAlign: "right" }}
                          >
                            Amount
                          </p>
                        </Col>
                      </Row>
                      {orderdatas.map((resp, index) => {
                        return (
                          <div key={index}>
                            {resp.outlet_id === res.outlet_id ? (
                              <Row>
                                <Col xs={4} sm={3}>
                                  <p>{resp.sku_name}</p>
                                </Col>
                                <Col>
                                  <p style={{ textAlign: "right" }}>
                                    {resp.item_price_unit}
                                  </p>
                                </Col>
                                <Col>
                                  <p style={{ textAlign: "right" }}>
                                    {resp.item_qty}
                                  </p>
                                </Col>
                                <Col>
                                  <p style={{ textAlign: "right" }}>
                                    {resp.order_amt}
                                  </p>
                                </Col>
                              </Row>
                            ) : null}
                          </div>
                        );
                      })}
                    </Container>
                    <Divider style={{ marginTop: "1.5rem" }} />
                    <Container className="scheme-container">
                      <Row>
                        <Col xs={4} sm={3}>
                          <p
                            className="text-bold"
                            style={{ marginTop: "2rem" }}
                          >
                            Scheme Discount
                          </p>
                        </Col>
                        <Col></Col>
                        <Col></Col>
                        <Col>
                          <p>{res.scheme_discount}</p>
                        </Col>
                      </Row>
                    </Container>
                    <Divider style={{ marginTop: "1.5rem" }} />
                    <Container className="total-container">
                      <Row>
                        <Col xs={4} sm={3}>
                          <p
                            className="text-bold"
                            style={{ marginTop: "2rem" }}
                          >
                            Total
                          </p>
                        </Col>
                        <Col></Col>
                        <Col></Col>
                        <Col>
                          <p
                            className="text-bold"
                            style={{
                              marginTop: "2rem",
                              fontWeight: "bold",
                              textAlign: "right",
                            }}
                          >
                            {res.total_quantity}
                          </p>
                        </Col>
                      </Row>
                    </Container>
                    <Divider style={{ marginTop: "1.5rem" }} />
                    {returndatas.length > 0 ? (
                      <>
                        <Row>
                          <Col>
                            <p
                              className="text-bold"
                              style={{
                                marginTop: "2rem",
                                marginLeft: "1.5rem",
                                color: "#F57F17",
                              }}
                            >
                              Type: Return
                            </p>
                          </Col>
                        </Row>
                        {returndatas.map((resps, indec) => {
                          return (
                            <div key={indec}>
                              {resps.outlet_id === res.outlet_id ? (
                                <Container>
                                  <Row>
                                    <Col sm={2}>
                                      <p style={{ marginLeft: "1.5rem" }}>
                                        {resps.sku_name}
                                      </p>
                                    </Col>
                                    <Col sm={2}>
                                      <p>{resps.order_return_reason}</p>
                                    </Col>
                                    <Col sm={2}>
                                      <p>{resps.item_price_unit}</p>
                                    </Col>
                                    <Col sm={2}>
                                      <p>{resps.item_qty}</p>
                                    </Col>
                                    <Col sm={2}>
                                      <p>{resps.return_order_amt}</p>
                                    </Col>
                                  </Row>
                                </Container>
                              ) : null}
                            </div>
                          );
                        })}
                      </>
                    ) : null}

                    {activitydatas.length > 0 ? (
                      <>
                        {activitydatas.map((resss, indexxx) => {
                          return (
                            <div key={indexxx}>
                              {resss.outlet_id === res.outlet_id ? (
                                <>
                                  <Row>
                                    <Col style={{ fontWeight: "bold" }}>
                                      <p
                                        className="text-bold"
                                        style={{
                                          color: "#CC7F3B",
                                          marginTop: "1rem",
                                          marginLeft: "14%",
                                        }}
                                      >
                                        Type: Activity
                                      </p>
                                    </Col>
                                  </Row>
                                  <Container className="section-containers table-header activity">
                                    <Row>
                                      <Col>
                                        <p className="col-headings">
                                          Customer Name
                                        </p>
                                        <p
                                          className="col-headings"
                                          style={{ color: "#CC7F3B" }}
                                        >
                                          SKU Name
                                        </p>
                                      </Col>
                                      <Col>
                                        <p
                                          className="col-headings"
                                          style={{ textAlign: "right" }}
                                        >
                                          Remarks
                                        </p>
                                        <p
                                          className="col-headings"
                                          style={{ textAlign: "right" }}
                                        >
                                          Follow up
                                        </p>
                                      </Col>
                                    </Row>
                                  </Container>
                                </>
                              ) : null}
                            </div>
                          );
                        })}

                        {activitydatas.map((ress, inde) => {
                          return (
                            <div key={inde}>
                              {ress.outlet_id === res.outlet_id ? (
                                <Container className="section-containers activity">
                                  <Row
                                    style={{ borderBottom: "1px solid #bbb" }}
                                  >
                                    <Col>
                                      <p
                                        style={{
                                          marginTop: "2rem",
                                          color: "amber",
                                        }}
                                      >
                                        {ress.hospital_customer_name}{" "}
                                        {ress.user_type}
                                      </p>
                                      <p
                                        style={{
                                          marginTop: "2rem",
                                          color: "#CC7F3B",
                                        }}
                                      >
                                        {ress.sku_name}
                                      </p>
                                    </Col>
                                    <Col>
                                      <p
                                        style={{
                                          marginTop: "2rem",
                                          color: "amber",
                                          textAlign: "right",
                                        }}
                                      >
                                        {ress.remark}
                                      </p>
                                      <p
                                        style={{
                                          marginTop: "2rem",
                                          color: "amber",
                                          textAlign: "right",
                                        }}
                                      >
                                        {ress.follow_up}
                                      </p>
                                    </Col>
                                  </Row>
                                </Container>
                              ) : null}
                            </div>
                          );
                        })}
                      </>
                    ) : null}
                  </div>
                );
              })}
            </>
          ) : null}
          {returndatas && returndates.length > 0 ? (
            <>
              {returndates.map((rr, ss) => {
                return (
                  <div key={ss}>
                    <Container className="section-containers">
                      <Row>
                        <Col>
                          <p
                            className="text-center"
                            style={{ marginTop: "2rem" }}
                          >
                            {rr.outlet_id}, {rr.outlet_name}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p
                            className="text-center text-bold"
                            style={{ color: "#00BFFF", marginTop: "1rem" }}
                          >
                            Type: Return
                          </p>
                        </Col>
                      </Row>
                    </Container>
                    <Container className="section-containers">
                      <Row>
                        <Col sm={2}>
                          <p
                            className="text-bold"
                            style={{ marginTop: "2rem", marginLeft: "1.5rem" }}
                          >
                            SKU Name
                          </p>
                        </Col>
                        <Col sm={2}>
                          <p
                            className="text-bold"
                            style={{ marginTop: "2rem" }}
                          >
                            Unit Price
                          </p>
                        </Col>
                        <Col sm={2}>
                          <p
                            className="text-bold"
                            style={{ marginTop: "2rem" }}
                          >
                            Unit
                          </p>
                        </Col>
                        <Col sm={2}>
                          <p
                            className="text-bold"
                            style={{ marginTop: "2rem" }}
                          >
                            Amount
                          </p>
                        </Col>
                      </Row>
                      {returndatas.map((resps, indec) => {
                        return (
                          <div key={indec}>
                            {resps.outlet_id === rr.outlet_id ? (
                              <Row>
                                <Col sm={2}>
                                  <p style={{ marginLeft: "1.5rem" }}>
                                    {resps.sku_name}
                                  </p>
                                </Col>
                                <Col sm={2}>
                                  <p>{resps.item_price_unit}</p>
                                </Col>
                                <Col sm={2}>
                                  <p>{resps.item_qty}</p>
                                </Col>
                                <Col sm={2}>
                                  <p>{resps.return_order_amt}</p>
                                </Col>
                              </Row>
                            ) : null}
                          </div>
                        );
                      })}
                    </Container>
                    <Divider style={{ marginTop: "1.5rem" }} />
                    <Container className="section-containers">
                      <Row>
                        <Col sm={2}>
                          <p
                            className="text-bold"
                            style={{ marginTop: "2rem", marginLeft: "1.5rem" }}
                          >
                            Scheme Discount
                          </p>
                        </Col>
                        <Col sm={2}></Col>
                        <Col sm={2}></Col>
                        <Col sm={2}>
                          <p>{rr.scheme_discount}</p>
                        </Col>
                      </Row>
                    </Container>
                    <Divider style={{ marginTop: "1.5rem" }} />
                    <Container className="section-containers">
                      <Row>
                        <Col sm={2}>
                          <p
                            className="text-bold"
                            style={{ marginTop: "2rem", marginLeft: "1.5rem" }}
                          >
                            Total
                          </p>
                        </Col>
                        <Col sm={2}></Col>
                        <Col sm={2}></Col>
                        <Col sm={2}>
                          <p className="text-bold">{rr.total_quantity}</p>
                        </Col>
                      </Row>
                    </Container>
                    <Divider style={{ marginTop: "1.5rem" }} />
                    {activitydatas.length > 0 ? (
                      <>
                        <Row>
                          <Col style={{ fontWeight: "bold" }}>
                            <p
                              className="text-bold text-left"
                              style={{
                                color: "#CC7F3B",
                                marginTop: "1rem",
                                textAlign: "left",
                              }}
                            >
                              Type: Activity
                            </p>
                          </Col>
                        </Row>
                        <Container className="section-containers">
                          <Row>
                            <Col sm={2}>
                              <p
                                className="text-bold"
                                style={{
                                  marginTop: "2rem",
                                  marginLeft: "1.5rem",
                                }}
                              >
                                Customer Name
                              </p>
                              <p
                                className="text-bold"
                                style={{ marginLeft: "1.5rem", color: "amber" }}
                              >
                                SKU Name
                              </p>
                            </Col>
                            <Col sm={2}>
                              <p
                                className="text-bold"
                                style={{
                                  marginTop: "2rem",
                                  textAlign: "right",
                                }}
                              >
                                Remarks
                              </p>
                              <p
                                className="text-bold"
                                style={{
                                  marginTop: "2rem",
                                  textAlign: "right",
                                }}
                              >
                                Follow up
                              </p>
                            </Col>
                          </Row>
                        </Container>
                        {activitydatas.map((ress, inde) => {
                          return (
                            <div key={inde}>
                              {ress.outlet_id === rr.outlet_id ? (
                                <Container>
                                  <Row>
                                    <Col sm={2}>
                                      <p
                                        style={{
                                          marginTop: "2rem",
                                          marginLeft: "1.5rem",
                                        }}
                                      >
                                        {ress.hospital_customer_name}{" "}
                                        {ress.user_type}
                                      </p>
                                      <p
                                        style={{
                                          marginTop: "2rem",
                                          marginLeft: "1.5rem",
                                          color: "amber",
                                        }}
                                      >
                                        {ress.sku_name}
                                      </p>
                                    </Col>
                                    <Col sm={2}>
                                      <p>{ress.remark}</p>
                                      <p>{ress.follow_up}</p>
                                    </Col>
                                  </Row>
                                </Container>
                              ) : null}
                            </div>
                          );
                        })}
                      </>
                    ) : null}
                  </div>
                );
              })}
            </>
          ) : null}
          {/* Activity Dates and Activity Data Component */}
          {activitydates && activitydatas.length > 0 ? (
            <>
              {activitydates.map((ree, see) => {
                return (
                  <div key={see}>
                    <Container>
                      <Row>
                        <Col>
                          <p
                            className="text-center rounded"
                            style={{
                              marginTop: "2rem",
                              backgroundColor: "#FF9966",
                              padding: "0.5rem",
                              color: "black",
                            }}
                          >
                            {ree.outlet_id}, {ree.outlet_name}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ fontWeight: "bold" }}>
                          <p
                            className="text-left text-bold"
                            style={{
                              color: "#CC7F3B",
                              marginTop: "1rem",
                              textAlign: "left",
                            }}
                          >
                            Type: Activity
                          </p>
                        </Col>
                      </Row>
                    </Container>
                    <Container className="section-containers activity">
                      <Row className="table-header">
                        <Col>
                          <p className="col-headings">Customer Name</p>
                          <p
                            className="col-headings"
                            style={{ color: "#CC7F3B" }}
                          >
                            SKU Name
                          </p>
                        </Col>
                        <Col>
                          <p
                            className="col-headings"
                            style={{ textAlign: "right" }}
                          >
                            Remarks
                          </p>
                          <p
                            className="col-headings"
                            style={{ textAlign: "right" }}
                          >
                            Follow up
                          </p>
                        </Col>
                      </Row>
                      {activitydatas.map((ress, inde) => {
                        return (
                          <div key={inde}>
                            {ress.outlet_id === ree.outlet_id ? (
                              <Container>
                                <Row style={{ borderBottom: "1px solid #bbb" }}>
                                  <Col>
                                    <p
                                      style={{
                                        marginTop: "2rem",
                                        color: "amber",
                                      }}
                                    >
                                      {ress.hospital_customer_name}{" "}
                                      {ress.user_type}
                                    </p>
                                    <p
                                      style={{
                                        marginTop: "2rem",
                                        color: "#CC7F3B",
                                      }}
                                    >
                                      {ress.sku_name}
                                    </p>
                                  </Col>
                                  <Col>
                                    <p
                                      style={{
                                        marginTop: "2rem",
                                        color: "amber",
                                        textAlign: "right",
                                      }}
                                    >
                                      {ress.remark}
                                    </p>
                                    <p
                                      style={{
                                        marginTop: "2rem",
                                        color: "amber",
                                        textAlign: "right",
                                      }}
                                    >
                                      {ress.follow_up}
                                    </p>
                                  </Col>
                                </Row>
                              </Container>
                            ) : null}
                          </div>
                        );
                      })}
                    </Container>
                  </div>
                );
              })}
            </>
          ) : null}
        </div>
      </ScrollView>

      {/* Modal Component */}
      {ReactDOM.createPortal(
        <ModalOverlay />,
        document.getElementById("modal-root")
      )}
    </NativeBaseProvider>
  );
};

export default App;
const styles = {
  right: {
    marginLeft: "auto",
    marginRight: 0,
  },

  center: {},
};
